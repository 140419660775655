// third-party
import { createSlice } from '@reduxjs/toolkit';
import { db } from 'utils/supabaseClient';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ==============================|| SLICE - KANBAN ||============================== //

const initialState = {
  error: null,
  columns: [],
  columnsOrder: [],
  comments: [],
  items: [],
  profiles: [],
  selectedItem: false,
  userStory: [],
  userStoryOrder: []

};



const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // ADD COLUMN
    addColumnSuccess(state, action) {
      state.columns = action.payload.columns;
      state.columnsOrder = action.payload.columnsOrder;
    },

    // EDIT COLUMN
    editColumnSuccess(state, action) {
      state.columns = action.payload.columns;
    },

    // UPDATE COLUMN ORDER
    updateColumnOrderSuccess(state, action) {
      state.columnsOrder = action.payload.columnsOrder;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      state.columns = action.payload.columns;
      state.columnsOrder = action.payload.columnsOrder;
    },

    // ADD ITEM
    addItemSuccess(state, action) {
      state.items = action.payload.items;
      state.columns = action.payload.columns;
      state.userStory = action.payload.userStory;
    },

    // EDIT ITEM
    editItemSuccess(state, action) {
      state.items = action.payload.items;
      state.columns = action.payload.columns;
      state.userStory = action.payload.userStory;
    },

    // UPDATE COLUMN ITEM ORDER
    updateColumnItemOrderSuccess(state, action) {
      state.columns = action.payload.columns;
    },

    // SELECT ITEM
    selectItemSuccess(state, action) {
      state.selectedItem = action.payload.selectedItem;
    },

    // ADD ITEM COMMENT
    addItemCommentSuccess(state, action) {
      state.items = action.payload.items;
      state.comments = action.payload.comments;
    },

    // DELETE ITEM
    deleteItemSuccess(state, action) {
      state.items = action.payload.items;
      state.columns = action.payload.columns;
      state.userStory = action.payload.userStory;
    },

    // ADD STORY
    addStorySuccess(state, action) {
      state.userStory = action.payload.userStory;
      state.userStoryOrder = action.payload.userStoryOrder;
    },

    // EDIT STORY
    editStorySuccess(state, action) {
      state.userStory = action.payload.userStory;
    },

    // UPDATE STORY ORDER
    updateStoryOrderSuccess(state, action) {
      state.userStoryOrder = action.payload.userStoryOrder;
    },

    // UPDATE STORY ITEM ORDER
    updateStoryItemOrderSuccess(state, action) {
      state.userStory = action.payload.userStory;
    },

    // ADD STORY COMMENT
    addStoryCommentSuccess(state, action) {
      state.userStory = action.payload.userStory;
      state.comments = action.payload.comments;
    },

    // DELETE STORY
    deleteStorySuccess(state, action) {
      state.userStory = action.payload.userStory;
      state.userStoryOrder = action.payload.userStoryOrder;
    },

    // GET COLUMNS
    getColumnsSuccess(state, action) {
      state.columns = action.payload;
    },

    // GET COLUMNS ORDER
    getColumnsOrderSuccess(state, action) {
      state.columnsOrder = action.payload;
    },

    // GET COMMENTS
    getCommentsSuccess(state, action) {
      state.comments = action.payload;
    },

    // GET PROFILES
    getProfilesSuccess(state, action) {
      state.profiles = action.payload;
    },

    // GET ITEMS
    getItemsSuccess(state, action) {
      state.items = action.payload;
    },

    // GET USER STORY
    getUserStorySuccess(state, action) {
      state.userStory = action.payload;
    },

    // GET USER STORY ORDER
    getUserStoryOrderSuccess(state, action) {
      state.userStoryOrder = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getColumns() {
  return async () => {
    try {
      //const response = await axios.get('/api/kanban/columns');
      const { data: columnsData } = await db.from('columns').select('*');
      const { data: columnsItemsData} = await db.from('columns_items').select('*');

      //console.log('columns api', response.data.columns);
      //console.log('columns db', columnsData);
      //console.log('columns_items db', columnsItemsData);

      // Map through each column and add an array of itemIds
      const columnsWithItems = columnsData.map((column) => {
        // Filter the column-item associations to get only those associated with the current column
        const columnItems = columnsItemsData.filter((ci) => ci.column_id === column.id);
        // Extract itemIds from the associations
        const itemIds = columnItems.map((ci) => ci.item_id);
        // Return the column object with the added itemIds array
        return { ...column, itemIds };
      });

      //console.log('columns with items', columnsWithItems);

      dispatch(slice.actions.getColumnsSuccess(columnsWithItems));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function getColumnsOrder() {
  return async () => {
    try {
      //const response = await axios.get('/api/kanban/columns-order');
      const data = await db.from('columns_order').select('column_id').order('sequence');
      const columnIds = data.data.map((item) => item.column_id);
      //console.log('columns order api', response.data.columnsOrder);
      //console.log('column order db', columnIds);
      dispatch(slice.actions.getColumnsOrderSuccess(columnIds));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getComments() {
  return async () => {
    try {
      //const response = await axios.get('/api/kanban/comments');
      const {data} = await db.from('comments').select();
      //console.log('comments api', response.data.comments);
      //console.log('comments db', data);
      dispatch(slice.actions.getCommentsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProfiles() {
  return async () => {
    try {
      //const response = await axios.get('/api/kanban/profiles');
      const {data} = await db.from('profiles').select();
      //console.log('profile api:', response.data.profiles);
      //console.log('profile db:', data);
      dispatch(slice.actions.getProfilesSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getItems() {
  return async (dispatch) => {
    try {
      //const response = await axios.get('/api/kanban/items');
      

      const { data: items } = await db.from('items').select('*');

      // Fetch attachments and commentIds for each item
      for (const item of items) {
        // Fetch attachments for the current item
        const { data: attachments } = await db
          .from('attachments')
          .select('id')
          .eq('item_id', item.id);

        // Fetch commentIds for the current item
        const { data: comments } = await db
          .from('comments')
          .select('id')
          .eq('item_id', item.id);

        // Inject attachments and commentIds into the item object
        item.attachments = attachments.map(attachment => attachment.id) || [];
        item.commentIds = comments.map(comment => comment.id) || [];
      }
      //console.log('items api:', response.data.items);
      //console.log('items db:', items);
      //console.log('items db', items);
      dispatch(slice.actions.getItemsSuccess(items));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
}



export function getUserStory() {
  
  return async (dispatch) => {
    try {

      //const response = await axios.get('/api/kanban/userstory');
      //console.log('user_stories_api:', response.data.userStory);

      const { data: userStories } = await db.from('user_stories').select('*');
      // Fetch items for each user story and populate ItemIds array
      for (const userStory of userStories) {

        const { data: items } = await db
          .from('items')
          .select('id')
          .eq('user_story_id', userStory.id);

        const { data: comments } = await db
        .from('comments')
        .select('id')
        .eq('user_story_id', userStory.id);

        // Inject attachments and commentIds into the item object

        userStory.commentIds = comments.map(comment => comment.id) || [];
        userStory.itemIds = items.map(item => item.id) || [];
      }
      //console.log('user_stories_db:', userStories);
      dispatch(slice.actions.getUserStorySuccess(userStories));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
}

export function getUserStoryOrder() {
  return async () => {
    try {
      
      const data = await db.from('user_story_order').select('user_story_id');
      let userStoryIds = data.data;
      const Ids = userStoryIds.map(item => item.user_story_id);
      dispatch(slice.actions.getUserStoryOrderSuccess(Ids));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addColumn(column, columns, columnsOrder) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/add-column', { column, columns, columnsOrder });
      console.log('column', column);
      console.log('columns', columns);
      console.log('columnsOrder', columnsOrder);
      dispatch(slice.actions.addColumnSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editColumn(column, columns) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/edit-column', { column, columns });
      dispatch(slice.actions.editColumnSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateColumnOrder(columnsOrder) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/update-column-order', { columnsOrder });
      dispatch(slice.actions.updateColumnOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteColumn(columnId, columnsOrder, columns) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/delete-column', { columnId, columnsOrder, columns });
      dispatch(slice.actions.deleteColumnSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addItem(columnId, columns, item, items, storyId, userStory) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/add-item', { columnId, columns, item, items, storyId, userStory });
      dispatch(slice.actions.addItemSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editItem(columnId, columns, item, items, storyId, userStory) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/edit-item', { items, item, userStory, storyId, columns, columnId });
      dispatch(slice.actions.editItemSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateColumnItemOrder(columns) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/update-item-order', { columns });
      dispatch(slice.actions.updateColumnItemOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function selectItem(selectedItem) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/select-item', { selectedItem });
      dispatch(slice.actions.selectItemSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addItemComment(itemId, comment, items, comments) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/add-item-comment', { items, itemId, comment, comments });
      dispatch(slice.actions.addItemCommentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteItem(itemId, items, columns, userStory) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/delete-item', { columns, itemId, userStory, items });
      dispatch(slice.actions.deleteItemSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addStory(story) {
  return async () => {
    try {
      const { data, error } = await db
        .from('user_stories')
        .insert([story]);

      if (error) throw error;

      // Assuming the response includes the updated list of stories
      dispatch(slice.actions.addStorySuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
}

export function editStory(storyId, updates) {
  return async () => {
    try {
      const { data, error } = await db
        .from('user_stories')
        .update(updates)
        .match({ id: storyId });

      if (error) throw error;

      // Assuming the response includes the updated story object
      dispatch(slice.actions.editStorySuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
}

export function updateStoryOrder(userStoryOrder) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/update-story-order', { userStoryOrder });
      dispatch(slice.actions.updateStoryOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateStoryItemOrder(userStory) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/update-storyitem-order', { userStory });
      dispatch(slice.actions.updateStoryItemOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addStoryComment(storyId, comment, comments, userStory) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/add-story-comment', { userStory, storyId, comment, comments });
      dispatch(slice.actions.addStoryCommentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteStory(storyId) {
  return async () => {
    try {
      const { data, error } = await db
        .from('user_stories')
        .delete()
        .match({ id: storyId });

      if (error) throw error;

      // Optionally, filter out the deleted story from local state or fetch updated list
      dispatch(slice.actions.deleteStorySuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
}
