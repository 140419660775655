
import logoIcon from 'assets/images/logo.png';


const LogoIcon = () => {

  return (

     <div><img src={logoIcon} alt="icon logo" width="36" /></div>
     
  );
};

export default LogoIcon;
