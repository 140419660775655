// src/pages/NotFound.js
import React from 'react';
import { Typography, Container } from '@mui/material';

const NotFound = () => {
  return (
    <Container>
      <Typography variant="h1" align="center" gutterBottom>
        404
      </Typography>
      <Typography variant="h4" align="center">
        Page Not Found
      </Typography>
    </Container>
  );
};

export default NotFound;
