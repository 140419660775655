import { useContext, useState, useEffect, createContext } from 'react';
import { db } from '../utils/supabaseClient';
import axios from 'axios';
import config from 'config';

const AuthContext = createContext({
  session: null,
  user: null,
  logout: () => {},
  isLoggedIn: false,
  register: () => {},
  login: () => {},
  resetPassword: () => {}, // Add resetPassword to the context
  updatePassword: () => {}, // Add updatePassword to the context
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const setData = async () => {
      const { data: { session }, error } = await db.auth.getSession();
      if (error) throw error;
      setSession(session);
      setUser(session?.user);
      setLoading(false);
    };

    const { data: listener } = db.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setUser(session?.user);
      setLoading(false);
    });

    setData();

    return () => {
      listener?.subscription.unsubscribe();
    };
  }, []);

  const logout = async () => {
    await db.auth.signOut();
  };

  const register = async (email, password) => {
    const { data, error } = await db.auth.signUp(
      {
        email: email,
        password: password,
        options: {
          data: {
            role: 'jobseeker',
          },
        },
      }
    );
    if (error) {
      console.error('error:', error);
      throw new Error(error.message);
    }
    if (data) {
      await create_assistant(data.user.id);
    }
  };

  const create_assistant = async (user_id) => {
    try {
      const apiResponse = await axios.post(`${config.apiBaseUrl}/user/create`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const result = JSON.parse(apiResponse.data);
      if (result.assistant_id && result.thread_id) {
        const projectData = {
          user_id: user_id,
          assistant_id: result.assistant_id,
          thread_id: result.thread_id,
        };
        const { error } = await db.from('user_assistant_data').insert([projectData]);
        if (error) {
          console.error('Error adding project:', error);
          alert('Failed to add new project');
        } else {
          console.log('Project added successfully');
        }
      } else {
        alert('API response is missing assistant_id or thread_id.');
      }
    } catch (error) {
      console.error('Error during project creation:', error);
      alert('An error occurred during project creation.');
    }
  };

  const login = async (email, password) => {
    const { user, error } = await db.auth.signInWithPassword({
      email: email,
      password: password,
    });
    if (error) {
      console.log(error);
      throw new Error(error.message);
    }
    setSession(db.auth.session());
    setUser(user);
    return user;
  };

  const resetPassword = async (email) => {
    const { error } = await db.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/reset-password`,
    });
    if (error) {
      throw new Error(error.message);
    }
  };

  const updatePassword = async (password) => {
    const { data, error } = await db.auth.updateUser({
      password: password,
    });
    if (error) {
      throw new Error(error.message);
    }
    return data;
  };

  const isLoggedIn = !!user;

  const value = {
    session,
    user,
    logout,
    isLoggedIn,
    register,
    login,
    resetPassword, // Add resetPassword to the value
    updatePassword, // Add updatePassword to the value
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('context must be used inside provider');
  return context;
};

export default useAuth;
