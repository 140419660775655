// project-imports
import { FormattedMessage } from 'react-intl';
import {
  Transmit, Check, Buildings, Convertshape, Radar2, ProfileCircle, ElementPlus, ProfileTick, Graph, Signpost, Microscope, MedalStar, LikeDislike, GlobalSearch, ArrangeHorizontalSquare, Briefcase, People, Kanban, UserTag, KyberNetwork, Messages2, Calendar1, Profile2User, Bill, UserSquare, ShoppingBag, DocumentText1, LampOn
} from 'iconsax-react';


// icons
const icons = {
  applications: KyberNetwork,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  invoice: Bill,
  profile: UserSquare,
  ecommerce: ShoppingBag,
  project: DocumentText1,
  agents: UserTag,
  samplePage: Bill,
  idea: LampOn,
  people: People,
  job: Briefcase,
  match: ArrangeHorizontalSquare,
  search: GlobalSearch,
  evaluate: LikeDislike,
  career: MedalStar,
  careerpath: Microscope,
  path: Signpost,
  dashboard: Graph,
  accept: ProfileTick,
  development: ElementPlus,
  profile2: ProfileCircle,
  radar: Radar2,
  coaching: Convertshape,
  building: Buildings,
  gap: Check,
  career1: Transmit,
  branding: MedalStar,
  interview: Profile2User

  
};

// Define the full menu
const fullMenu = [
  {
    id: 'dashboard',
    title: <FormattedMessage id="Dashboard" />,
    type: 'item',
    url: '/dashboard',
    icon: icons.dashboard
  },
  {
    id: 'user-profile',
    title: <FormattedMessage id="Profile" />,
    type: 'item',
    url: '/user/profile',
    icon: icons.profile
  },
  {
    id: 'cv',
    title: <FormattedMessage id="Resume & CV" />,
    type: 'collapse',
    icon: icons.project,
    children: [
      {
        id: 'show_upload',
        title: <FormattedMessage id="Upload & Show" />,
        url: '/user/show-cv',
        type: 'item',
        icon: icons.project
      },
      {
        id: 'cv_enhancement',
        title: <FormattedMessage id="Enhancements" />,
        url: '/user/enhancement',
        type: 'item',
        icon: icons.project
      },
      {
        id: 'cv_build',
        title: <FormattedMessage id="CV Builder" />,
        url: '/user/builder',
        type: 'item',
        icon: icons.project
      },
     
    ]
  },
  {
    id: 'jobsearch',
    title: <FormattedMessage id="Job Search" />,
    url: '/job-search',
    type: 'item',
    icon: icons.search
  },
  {
    id: 'user-jobsearch',
    title: <FormattedMessage id="Job Search" />,
    url: '/user/job-search',
    type: 'item',
    icon: icons.search
  },
  {
    id: 'user-jobgaps',
    title: <FormattedMessage id="Jobs Gap" />,
    url: '/user/job-gaps',
    type: 'item',
    icon: icons.gap
  },
  {
    id: 'user-career',
    title: <FormattedMessage id="Career" />,
    url: '/user/career',
    type: 'item',
    icon: icons.career1
  },
  {
    id: 'user-branding',
    title: <FormattedMessage id="Branding" />,
    url: '/user/branding',
    type: 'item',
    icon: icons.branding
  },
  {
    id: 'user-interviews',
    title: <FormattedMessage id="Interviews" />,
    url: '/user/interviews',
    type: 'item',
    icon: icons.interview
  },
  {
    id: 'career',
    title: <FormattedMessage id="Career Development" />,
    type: 'collapse',
    icon: icons.development,
    children: [
      {
        id: 'careerpath',
        title: <FormattedMessage id="Career Paths" />,
        type: 'item',
        url: '/careerpath',
        icon: icons.project
      },
      {
        id: 'learning',
        title: <FormattedMessage id="Learning & Development" />,
        url: '/learning-development',
        type: 'item',
        icon: icons.job
      },
      {
        id: 'networking',
        title: <FormattedMessage id="Networking Opportunities" />,
        url: '/networking',
        type: 'item',
        icon: icons.job
      }
    ]
  },
  {
    id: 'employer-profile',
    title: <FormattedMessage id="Profile Management" />,
    type: 'collapse',
    icon: icons.profile,
    children: [
      
      {
        id: 'profile-improvements',
        title: <FormattedMessage id="Profile Improvements" />,
        url: '/profile-improve',
        type: 'item',
        icon: icons.job
      },
      {
        id: 'personal-branding',
        title: <FormattedMessage id="Personal Branding" />,
        url: '/branding',
        type: 'item',
        icon: icons.job
      },
      
    ]
  },
  {
    id: 'insights',
    title: <FormattedMessage id="Market Insights" />,
    type: 'collapse',
    icon: icons.radar,
    children: [
      {
        id: 'job-market-trends',
        title: <FormattedMessage id="Job Market Trends" />,
        type: 'item',
        url: '/trends',
        icon: icons.project
      },
      {
        id: 'peer-comparisons',
        title: <FormattedMessage id="Peer Comparisons" />,
        url: '/peer-comparisons',
        type: 'item',
        icon: icons.job
      }
    ]
  },
  {
    id: 'coaching',
    title: <FormattedMessage id="Career Coaching" />,
    type: 'collapse',
    icon: icons.coaching,
    children: [
      {
        id: 'coaching-session',
        title: <FormattedMessage id="Coaching Sessions" />,
        type: 'item',
        url: '/coaching-session',
        icon: icons.project
      },
      {
        id: 'achievement-showcase',
        title: <FormattedMessage id="Achievement Showcase" />,
        url: '/achievement-showcase',
        type: 'item',
        icon: icons.job
      }
    ]
  },
  {
    id: 'jobseeker',
    title: <FormattedMessage id="Job Seekers" />,
    type: 'item',
    icon: icons.people,
    url: '/jobseeker'
  },
  {
    id: 'listjobs',
    title: <FormattedMessage id="Job Posts" />,
    type: 'item',
    url: '/listjobs',
    icon: icons.job
  },
  {
    id: 'agents-page',
    title: <FormattedMessage id="Employers" />,
    type: 'item',
    url: '/employers',
    icon: icons.building
  }
];

const filterMenuByRole = (menu, role) => {
  if (role === 'Employer') {
    // Filter for employer-specific items
    return menu.filter(item => ['listjobs', 'agents-page'].includes(item.id));
  } else if (role === 'jobseeker') {
    // Filter for jobseeker-specific items
    return menu.filter(item => ['user-interviews','user-branding', 'user-career', 'user-jobgaps', 'user-jobsearch', 'cv', 'user-profile', 'application', 'profile'].includes(item.id));
  } else {
    // Default to showing all items if role is unknown
    return menu;
  }
};

const getMenuItems = (role) => {
  const filteredMenu = filterMenuByRole(fullMenu, role);

  return {
    items: [
      {
        id: 'other',
        type: 'group',
        children: filteredMenu
      }
    ]
  };
};

const menuItems = (role) => {
  return getMenuItems(role);
};

export default menuItems;
