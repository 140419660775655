import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import CommonLayout from 'layout/CommonLayout';
import NotFound from 'pages/extra-pages/NotFound';

const ListCVFiles = Loadable(lazy(() => import('sections/apps/cvs/list')));
const ListJobSeeker = Loadable(lazy(() => import('sections/apps/jobseeker/list')));

const Profile = Loadable(lazy(() => import('sections/apps/jobseeker/profile')));
const UserProfile = Loadable(lazy(() => import('sections/apps/jobseeker/user_profile')));

const CVEnhancement = Loadable(lazy(() => import('sections/apps/jobseeker/cvenhancement')));
const Career = Loadable(lazy(() => import('sections/apps/jobseeker/career')));
const JobFit = Loadable(lazy(() => import('sections/apps/jobseeker/fit')));
const JobAlert = Loadable(lazy(() => import('sections/apps/jobseeker/jobalerts')));
const ViewCV = Loadable(lazy(() => import('sections/apps/jobseeker/viewcv')));
const UserShowCV = Loadable(lazy(() => import('sections/apps/jobseeker/user_showcv')));
const UserCVEnhancement = Loadable(lazy(() => import('sections/apps/jobseeker/user_cvenhancement')));
const UserCVBuilder = Loadable(lazy(() => import('sections/apps/jobseeker/user_cvbuilder')));
const UserSearch = Loadable(lazy(() => import('sections/apps/jobseeker/user_jobsearch')));
const UserJobDetail = Loadable(lazy(() => import('sections/apps/jobseeker/user_jobdetail')));
const UserJobGap = Loadable(lazy(() => import('sections/apps/jobseeker/user_jobgap')));
const UserJobGaps = Loadable(lazy(() => import('sections/apps/jobseeker/user_gaplist')));
const UserInterview = Loadable(lazy(() => import('sections/apps/jobseeker/user_interview')));
const UserInterviews = Loadable(lazy(() => import('sections/apps/jobseeker/user_interviews')));
const UserCareer = Loadable(lazy(() => import('sections/apps/jobseeker/user_career')));
const UserBranding = Loadable(lazy(() => import('sections/apps/jobseeker/user_branding')));
const UserOnboarding = Loadable(lazy(() => import('sections/apps/jobseeker/user_onboarding')));

const JobList = Loadable(lazy(() => import('pages/apps/jobs/list')));
const JobDetail = Loadable(lazy(() => import('sections/apps/jobs/detail')));
const JobGap = Loadable(lazy(() => import('sections/apps/jobseeker/gap')));
const ListJobs = Loadable(lazy(() => import('sections/apps/jobs/posts')));
const Trends = Loadable(lazy(() => import('sections/apps/jobs/trends')));
const SeekerFit = Loadable(lazy(() => import('sections/apps/jobs/fit')));
const JobGaps = Loadable(lazy(() => import('sections/apps/jobseeker/gaplist')));

const DashboardDefault = Loadable(lazy(() => import('sections/apps/dashboard/default')));
const ListEmployers = Loadable(lazy(() => import('sections/apps/employers/list')));

const Landing = Loadable(lazy(() => import('pages/landing')));

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/',
          children: [
            {
              path: 'dashboard',
              element: <DashboardDefault />,
            },
            {
              path: 'uploadcv',
              element: <ListCVFiles />,
            },
            {
              path: 'jobseeker',
              element: <ListJobSeeker />,
            },
            {
              path: '/jobseeker/:jobseekerId/profile',
              element: <Profile />,
            },
            {
              path: '/jobseeker/:jobseekerId/viewcv',
              element: <ViewCV />,
            },
            {
              path: '/jobseeker/:jobseekerId/enhancement',
              element: <CVEnhancement />,
            },
            {
              path: '/jobseeker/:jobseekerId/career',
              element: <Career />,
            },
            {
              path: '/jobseeker/:jobseekerId/jobfit',
              element: <JobFit />,
            },
            {
              path: '/jobseeker/:jobseekerId/jobgaps',
              element: <JobGaps />,
            },
            {
              path: '/job-search',
              element: <JobList />,
            },
            {
              path: '/jobs/:job_id',
              element: <JobDetail />,
            },
            {
              path: '/jobs/gap/:jobId/seeker/:jobseekerId',
              element: <JobGap />,
            },
            {
              path: '/jobs/seekers/fit/:jobId',
              element: <SeekerFit />,
            },
            {
              path: '/listjobs',
              element: <ListJobs />,
            },
            {
              path: '/job-alert',
              element: <JobAlert />,
            },
            {
              path: '/employers',
              element: <ListEmployers />,
            },
            {
              path: '/trends',
              element: <Trends />,
            },
          ],
        },
        {
          path: '/user',
          children: [
            {
              path: 'profile',
              element: <UserProfile />,
            },
            {
              path: 'show-cv',
              element: <UserShowCV />,
            },
            {
              path: 'enhancement',
              element: <UserCVEnhancement />,
            },
            {
              path: 'builder',
              element: <UserCVBuilder />,
            },
            {
              path: 'job-search',
              element: <UserSearch />,
            },
            {
              path: 'job-detail/:job_id',
              element: <UserJobDetail />,
            },
            {
              path: 'job-gap/:job_id',
              element: <UserJobGap />,
            },
            {
              path: 'job-gaps',
              element: <UserJobGaps />,
            },
            {
              path: 'interview/:job_id',
              element: <UserInterview />,
            },
            {
              path: 'interviews',
              element: <UserInterviews />,
            },
            {
              path: '/user/career',
              element: <UserCareer />,
            },
            {
              path: '/user/branding',
              element: <UserBranding />,
            },
          ],
        },
      ],
    },
    {
      path: '/',
      element: <CommonLayout layout="blank" />,
      children: [
        {
          path: 'home',
          element: <Landing />,
        },
        {
          path: '/user/onboarding',
          element: <UserOnboarding />,
        },
      ],
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ],
};

export default MainRoutes;
