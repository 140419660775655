import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// third-party
import { IntlProvider } from 'react-intl';

// project-imports
import useConfig from 'hooks/useConfig';

// load locales files
const loadLocaleData = (locale) => {
  console.log('locale:', locale);
  switch (locale) {
    case 'ar':
      return import('utils/locales/ar.json');
    case 'fr':
      return import('utils/locales/fr.json');
    case 'ro':
      return import('utils/locales/ro.json');
    case 'zh':
      return import('utils/locales/zh.json');
    case 'en':
    default:
      return import('utils/locales/en.json');
  }
};

// ==============================|| LOCALIZATION ||============================== //

const Locales = ({ children }) => {
  const { i18n } = useConfig();
  const [messages, setMessages] = useState();

  useEffect(() => {
    loadLocaleData(i18n).then((d) => {
      const localeData = d.default ? d.default : d;
     
      setMessages(localeData);
    });
  }, [i18n]);

  return (
    <>
      {messages ? (
        <IntlProvider locale={i18n} defaultLocale="en" messages={messages}>
          {children}
        </IntlProvider>
      ) : (
        <div>Loading translations...</div>
      )}
    </>
  );
};

Locales.propTypes = {
  children: PropTypes.node,
};

export default Locales;
