import PropTypes from 'prop-types';
import logoIcon from 'assets/images/logo.png';
// material-ui


/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = () => {
  return (
    // Use a flex container with inline styles for demonstration
    <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
      <img src={logoIcon} alt="icon logo" width="36" />
      {/* Increase font size with inline style */}
      <span style={{ marginLeft: '8px', fontSize: '22px' }}>CEVEPRO</span>
    </div>
  );
};



LogoMain.propTypes = {
  reverse: PropTypes.bool
};

export default LogoMain;
