
// material-ui
import { Stack, Typography } from '@mui/material';

// ==============================|| MAIN LAYOUT - FOOTER ||============================== //

const Footer = () => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{
      p: '0px 0px 0px',
      mt: 'auto',
      position: 'fixed',
      bottom: 0,
      backgroundColor: '#F8F9FA',
      width: '100%',
      marginTop:'30px'

    }}
  >
    <Typography variant="caption">&copy; CEVEPRO ♥ crafted by Team CEVEPRO</Typography>
    <Stack
      spacing={1.5}
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      sx={{ flexGrow: 1 }}
    >

    </Stack>
  </Stack>
);

export default Footer;
