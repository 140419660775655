import React, { useEffect, useState } from 'react';

// project-imports
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Loader from 'components/Loader';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';
import { dispatch } from 'store';
import { fetchMenu } from 'store/reducers/menu';
import { AuthProvider } from 'hooks/useAuth';
import { ConfigProvider } from 'contexts/ConfigContext';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchMenu()).then(() => {
      setLoading(false);
    });
  }, []);

  if (loading) return <Loader />;

  return (
    <ThemeCustomization>
      <AuthProvider>
        <ConfigProvider>
          <Locales>
            <RTLLayout>
              <ScrollTop>
                <Notistack>
                  <Routes />
                  <Snackbar />
                </Notistack>
              </ScrollTop>
            </RTLLayout>
          </Locales>
        </ConfigProvider>
      </AuthProvider>
    </ThemeCustomization>
  );
};

export default App;
