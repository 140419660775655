import { Link, Box, useMediaQuery } from '@mui/material';

// project-imports
import Search from './Search';
import Profile from './Profile';
import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';
import { MenuOrientation } from 'config';
import { ThemeDirection } from 'config';

const HeaderContent = () => {
  const { menuOrientation, themeDirection, onChangeDirection } = useConfig();
  const downLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const handleToggleDirection = () => {
    const newDirection = themeDirection === ThemeDirection.LTR ? ThemeDirection.RTL : ThemeDirection.LTR;
    onChangeDirection(newDirection);
  };
  

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}
      {!downLG && <Search />}
      {downLG && <Box sx={{ width: '100%', ml: 1 }} />}
     
      <Link
        className="header-link"
        color="secondary.main"
        underline="none"
        onClick={handleToggleDirection}
        component="button"
        sx={{ cursor: 'pointer' }}
      >
        {themeDirection === ThemeDirection.LTR ? 'عربي' : 'En'}
      </Link>
      {!downLG && <Profile />}
    </>
  );
};

export default HeaderContent;
